<template>
  <div>
    <v-container>
      <v-toolbar
        dense
        flat
        color="transparent"
      >
        <v-btn
          icon
          to="Home"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-toolbar-title>{{ $t('setup') }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card
        flat
        rounded="xl"
        class="my-3"
      >
        <v-list class="py-3">
          <v-list-item @click="ChangeAvatar =!ChangeAvatar">
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t('touxiang') }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-img :src="require('../assets/img/avatar/'+agentinfo.avatar+'.png')" />
            </v-list-item-avatar>
            <v-list-item-icon>
              <v-icon color="grey lighten-1">
                mdi-chevron-right
              </v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t('username') }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>{{ agentinfo.username }}</v-list-item-subtitle>
            </v-list-item-action>
            <v-list-item-icon />
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>UID</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>{{ agentinfo.id }}</v-list-item-subtitle>
            </v-list-item-action>
            <v-list-item-icon class="ml-3">
              <v-btn
                icon
                small
                @click="doCopy(agentinfo.id)"
              >
                <v-icon small>
                  mdi-content-copy
                </v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t('huobi') }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>{{ agentinfo.currency }}</v-list-item-subtitle>
            </v-list-item-action>
            <v-list-item-icon />
          </v-list-item>

          <v-list-item @click="ChangePW = true">
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t('password') }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action-text>
              {{ $t('genggai') }}
            </v-list-item-action-text>
            <v-list-item-icon>
              <v-icon color="grey lighten-1">
                mdi-chevron-right
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card>
    </v-container>

    <v-bottom-sheet
      v-model="ChangeAvatar"
      scrollable
    >
      <v-card
        class="rounded-t-xl pb-10"
      >
        <v-container :ref="'detail'+'3'">
          <v-row
            dense
            align="center"
          >
            <v-col cols="4">
              <v-btn
                icon
                @click="ChangeAvatar = false"
              >
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="4"
              class="text-center"
            >
              {{ $t('genggai') }} {{ $t('touxiang') }}
            </v-col>
          </v-row>
        </v-container>
        <v-card-text
          style="max-height: 500px;"
        >
          <v-row
            dense
            class="my-avatar-list"
          >
            <v-col
              v-for="n in 12"
              :key="n"
              cols="3"
            >
              <v-badge
                v-if="n==avatar_selected"
                bordered
                bottom
                overlap
                color="green"
                icon="mdi-check-bold"
                offset-x="20"
                offset-y="20"
                label=""
              >
                <v-avatar
                  size="72"
                  class="active"
                >
                  <v-img :src="require('../assets/img/avatar/'+n+'.png')" />
                </v-avatar>
              </v-badge>
              <v-avatar
                v-if="n!=avatar_selected"
                size="72"
                @click="selavatar(n)"
              >
                <v-img :src="require('../assets/img/avatar/'+n+'.png')" />
              </v-avatar>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="!valid"
            block
            x-large
            rounded
            color="primary"
            class="mt-3"
            @click.stop="editavatar"
          >
            {{ $t('tijiao') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet v-model="ChangePW">
      <v-card
        class="rounded-t-xl pb-10"
      >
        <v-container :ref="'detail'+'3'">
          <v-row
            dense
            align="center"
          >
            <v-col cols="4">
              <v-btn
                icon
                @click="ChangePW = false"
              >
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="4"
              class="text-center"
            >
              {{ $t('password_genggai') }}
            </v-col>
          </v-row>
        </v-container>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="password"
              filled
              rounded
              background-color="app_bg"
              :label="$t('password_yuanshi')"
              hide-details="auto"
              :append-icon="pw_show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="pw_show ? 'text' : 'password'"
              class="mb-3"
              @click:append="pw_show = !pw_show"
            />
            <v-text-field
              v-model="password2"
              filled
              rounded
              background-color="app_bg"
              :label="$t('password_xin')"
              hide-details="auto"
              :append-icon="pw_show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="pw_rules"
              :type="pw_show ? 'text' : 'password'"
              class="mb-3"
              @click:append="pw_show = !pw_show"
            />

            <v-btn
              :disabled="!valid"
              block
              x-large
              rounded
              color="primary"
              class="mt-5"
              @click.stop="changepwd"
            >
              {{ $t('tijiao') }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import store from "../store/"
  export default {
    name: 'Setup',
    components:{

    },
    data () {
      return {
        valid: true,
        password: '',
        password2: '',
        pw_show: false,
        pw_rules: [
          v => !!v || 'Required',
          v => (v && v.length >= 8) || 'Min 8 characters',
        ],
        ChangeAvatar: false,
        ChangePW: false,
        avatar_selected: 0,
        agentinfo: {}
      }
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
      if(this.getStorage('agent_id')){
        this.myinfo()
      }
    },
    beforeDestroy () {
      
    },
    methods: {
      myinfo() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
        }
        this.$server.myinfo(paramObj).then((response) => {
          if(response.code==200){
            this.agentinfo = response.data
          }
        })
      },
      selavatar(n) {
        this.avatar_selected = n
      },
      editavatar() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          field: 'avatar',
          value: this.avatar_selected,
        }
        this.$server.modifyinfo(paramObj).then((response) => {
          this.showprogress = false
          if(response.code==200){
            this.agentinfo.avatar = this.avatar_selected
            this.ChangeAvatar = false
            store.commit('agentinfo', this.agentinfo)
          }
        })
      },
      changepwd() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          field: 'password',
          password: this.password,
          password2: this.password2
        }
        this.$server.modifyinfo(paramObj).then((response) => {
          if(response.code==200){
            this.ChangePW = false
          }else{
            this.$snackbar.warning(response.msg)
          }
        })
      },
      doCopy: function (text) {
        text = ''+text//转string
        this.$copyText(text).then( (e)=>{
          console.log(e)
          this.$snackbar.info(this.$t('copy_ok'))
        }, function (e) {
          console.log(e)
        })
      },
    }
  }
</script>

<style>
  .my-avatar-list .v-avatar{
    border-radius: 50%;
    border: 3px solid transparent;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .my-avatar-list .v-avatar:hover{
    border-color: orange;
  }
  .my-avatar-list .v-avatar.active {
    border-color: #4CAF50;
  }
</style>